import styled from "styled-components";

export const Container = styled.section`
  padding-top: 15%;
  display: flex;
  justify-content: space-between;
  gap: 8rem;
  background: rgba(0,0,0,0);
  
  .hero-text{
    & > p{
      font-size: 1.8rem;
    }
    h1{
      font-size: 7rem;
      
      .highlight {
        color: var(--green);
      }
    }

    h3{
      color:var(--green);
      margin: 1rem 0;
    }

    p.small-resume{
      margin-bottom: 5rem;
    }

    .button{
      margin-top: 5rem;
      padding: 1.4rem 6rem;
    }

    .social-media{
      display: flex;
      margin: 4rem 0;
      gap: 3rem;

      a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5rem;
        height: 5rem;
        background-color: var(--green);  /* Changed from var(--black) to var(--green) */
        border-radius: 50%;
        transition: all 0.3s ease;  /* Changed to transition all properties smoothly */
        font-size: 2rem;
        color: #fff;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);  /* Added a subtle shadow in default state */
        
        &:hover{
          background-color: var(--pink);  /* Using pink on hover for contrast */
          transform: translateY(-5px);
          box-shadow: 0 10px 15px rgba(0,0,0,0.2);  /* Enhanced shadow on hover */
        }
      }
    }
  }

  .hero-image{
    img{
      max-width: 500px;
    }
  }

  @media(max-width: 960px){
    display: block;
    margin-top: 15%;
    .hero-text{
      display: block;
      width: 100%;
      text-align: center;
      
      p{
        width: 100%;
      }
      .social-media{
        justify-content: center;
      }
    }
    
    .hero-image{
      display: none;
    }
  }

  @media(max-width: 600px){
    margin-top: 35%;
  }
  @media(max-width: 480px){
    margin-top: 45%;
  }
`;