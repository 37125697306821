import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  /* Import fonts - using font-display: swap for better performance */
  @import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap&font-display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap&font-display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap&font-display=swap');

  :root{
    --pink: #5A639C;
    --black: #1A202C;
    --green: #5A639C;
    --blue: #016fb9;
    --section-title-color: #778da9;
    --font-mono: 'Space Mono', monospace;
    --font-sans: 'Roboto', sans-serif;
    --font-heading: 'Poppins', sans-serif;
    scroll-padding-top: 10rem;

    /* Light theme styles */
    &.light{
      /* Light theme styles here */
    }
  }

  /* Base styles */
  ul, li {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding:0;
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html{
    font-size: 62.5%;
    font-family: var(--font-mono);
  }

  body{
    font-size: 1.6rem;
    -webkit-font-smoothing: antialiased;
    background-color: var(--black);
    color: #FFFF;
    font-family: var(--font-mono) !important;
  }

  /* Typography */
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-mono) !important;
    font-weight: 700;
    letter-spacing: -0.05em;
  }

  p {
    font-family: var(--font-mono) !important;
    line-height: 1.6;
    letter-spacing: -0.02em;
  }

  body, input, textarea {
    font-family: var(--font-mono) !important;
    font-weight: 400;
  }

  button, .button {
    font-family: var(--font-heading);
    font-weight: 600;
  }

  a{
    text-decoration: none;
    font-family: var(--font-mono) !important;
  }

  code, pre {
    font-family: var(--font-mono);
    font-size: 1.4rem;
  }

  .highlight {
    font-family: var(--font-mono);
    color: var(--green);
  }

  /* Buttons */
  button, .button{
    border: none;
    cursor: pointer;
    background-color: var(--green);
    color: #FFFF;
    border-radius: 2rem;
    font-weight: 500;
    transition: filter 0.25s;
    letter-spacing: 0.05em;
    &:hover{
      filter: brightness(0.8);
    }
  }

  button:disabled, .button:disabled{
    filter: brightness(0.8);
    cursor: not-allowed;
  }

  /* Logo */
  .logo{
    font-family: var(--font-mono);
    font-size: 3rem;
    color: #FFFF;
    letter-spacing: -0.02em;
    font-weight: 700;
  }

  /* Navigation */
  nav a {
    font-family: var(--font-mono);
    font-weight: 400;
  }

  /* Section titles */
  section h2 {
    font-family: var(--font-mono) !important;
    letter-spacing: -0.05em;
  }

  /* Project */
  .project-title {
    font-family: var(--font-mono) !important;
    letter-spacing: -0.03em;
  }

  /* Form elements */
  input, textarea {
    font-family: var(--font-mono) !important;
  }
  
  /* Footer elements */
  footer {
    font-family: var (--font-mono) !important;
    
    small {
      font-family: var(--font-mono) !important;
      font-size: 1.2rem;
    }
    
    .contacts a, .social-media a {
      font-family: var(--font-mono) !important;
    }
  }
  
  /* Experience section - FIXED STYLES */
  #experience, 
  #experience * {
    font-family: var(--font-mono) !important;
  }
  
  /* Target the Event component from react-timeline-scribble */
  .rt-timeline-container,
  .rt-timeline-container *,
  .rt-event-container,
  .rt-event-container * {
    font-family: var(--font-mono) !important;
  }
  
  /* Target every possible class and element from the timeline library */
  [class^="rt-"],
  [class*=" rt-"],
  .timeline,
  .timeline *,
  .event,
  .event * {
    font-family: var(--font-mono) !important;
  }
  
  /* Additional Event component selectors */
  .event h3,
  .event h4,
  .event p,
  .event div,
  .event span {
    font-family: var(--font-mono) !important;
  }
  
  /* Direct override for all elements in Experience component */
  .TimelineWrapper *,
  .TimelineWrapper h1,
  .TimelineWrapper h3,
  .TimelineWrapper p,
  .TimelineWrapper div {
    font-family: var(--font-mono) !important;
  }
  
  /* Additional direct approach to override Event component styles */
  body .rt-timeline-container .rt-event-container p,
  body .rt-timeline-container .rt-event-container h3, 
  body .rt-timeline-container .rt-event-container h4,
  body .rt-timeline-container .rt-event-container div {
    font-family: var(--font-mono) !important;
    letter-spacing: -0.02em;
  }
`