import styled from "styled-components";

export const Container = styled.section`
  margin-top: 10rem;

  header {
    text-align: center;
    h2 {
      text-align: center;
      font-size: 4rem;
      color: var(--section-title-color);
    }
    p {
      color: var(--green);
      font-weight: 500;
    }
  }

  /* Social Media Styles */
  .social-media {
    display: flex;
    justify-content: center;
    gap: 2rem; /* Increased spacing between icons */
    margin-top: 3rem;
  }

  .social-media a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--green);
    color: #fff;
    font-size: 1.8rem;
    transition: all 0.3s ease;
  }

  .social-media a:hover {
    background-color: var(--pink);
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }

  .button {
    margin-top: 5rem;
    padding: 1.4rem 6rem;
  }

  .hero-image {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    
    img {
      max-width: 500px;
    }
  }

  .contacts {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    place-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      max-width: 30rem;
      gap: 2rem;
      background-color: var(--green);
      border-radius: 1.4rem;
      padding: 1.6rem 2.8rem;
      transition: all 0.3s ease;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      
      img {
        width: 4rem;
        transition: all 0.3s ease;
      }
      
      a {
        color: var(--black);
        font-weight: 500;
        transition: all 0.3s ease;
      }
      
      &:hover {
        background-color: var(--pink);
        transform: translateY(-5px);
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
        
        a {
          color: #FFF;
        }
      }
    }
  }

  @media(max-width: 960px) {
    margin-top: 15%;
    
    .hero-text {
      h1 {
        font-size: 5rem;
      }
    }
    
    .hero-image {
      display: none;
    }
    
    .contacts {
      flex-direction: column;
      div {
        width: 100%;
      }
    }
  }

  @media(max-width: 600px) {
    margin-top: 35%;
  }
  
  @media(max-width: 480px) {
    margin-top: 45%;
  }
`;
