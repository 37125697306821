import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  background-color: var(--background-color);
  margin-top: 15rem;

  @media (max-width: 960px) {
    align-items: center;
  }
`;

export const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  width: 100%;
  margin-bottom: 2rem;

  img {
    width: 100%;
    max-width: 400px;
    object-fit: cover;
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 960px) {
    display: none;
  }
`;

export const TimelineWrapper = styled.div`
  flex: 2;
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 2rem;

  h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
    color: var(--section-title-color);
    text-align: center;
    margin-bottom: 4rem;
  }

  @media (max-width: 960px) {
    h1 {
      font-size: 3rem;
      text-align: center;
    }
  }
`;

export const CustomEventStyle = styled.div`
  /* Override styles for the timeline and events */
  .rt-timeline-container * {
    font-family: 'Space Mono', monospace !important;
  }
  
  .rt-event-container * {
    font-family: 'Space Mono', monospace !important;
  }
  
  .rt-title {
    font-family: 'Space Mono', monospace !important;
    color: var(--section-title-color);
    font-size: 2.4rem;
  }
  
  .rt-subtitle {
    font-family: 'Space Mono', monospace !important;
    color: var(--green);
    font-size: 1.8rem;
  }
  
  .rt-date {
    font-family: 'Space Mono', monospace !important;
    color: #fff;
    font-size: 1.6rem;
  }
  
  .custom-event-text {
    font-family: 'Space Mono', monospace !important;
    font-size: 1.6rem;
    line-height: 1.6;
    margin-top: 1rem;
  }
  
  /* Fallback - apply styles to all possible elements */
  h3, h4, p, div, span {
    font-family: 'Space Mono', monospace !important;
  }
`;

export const SectionTitle = styled.div`
  text-align: center;
  margin-bottom: 4rem;
  
  h2 {
    font-size: 3.2rem;
    color: var(--section-title-color);
    display: inline-block;
    position: relative;
    
    @media (max-width: 768px) {
      font-size: 2.8rem;
      margin-bottom: 2rem;
    }
  }
`;