import React, { useState } from 'react';
import { Container, StacksGrid, StackCard, ExpandedStack, SkillCard, SkillIcon, SkillName, StackTitle, StackHeader } from './styles';
import { 
  FaNodeJs, FaAngular, FaReact, FaCss3Alt, FaJsSquare, FaLaravel, 
  FaDatabase, FaDocker, FaGitAlt, FaChevronDown, FaChevronUp,
  FaBootstrap
} from 'react-icons/fa';
import { SiTypescript, SiCsharp, SiDotnet, SiMicrosoftsqlserver, SiMysql, SiMongodb, SiPostgresql, SiTailwindcss, SiRedis, SiAzuredevops } from 'react-icons/si';
import { FaPhp, FaPython, FaVuejs } from 'react-icons/fa6';
import ScrollAnimation from 'react-animate-on-scroll';

// Organize skills into stacks
const skillStacks = [
  {
    name: "Frontend",
    icon: <FaReact />,
    color: "#61DAFB",
    skills: [
      { name: 'JavaScript', icon: <FaJsSquare /> },
      { name: 'TypeScript', icon: <SiTypescript /> },
      { name: 'React', icon: <FaReact /> },
      { name: 'Angular', icon: <FaAngular /> },
      { name: 'Vue', icon: <FaVuejs /> },
      { name: 'Bootstrap', icon: <FaBootstrap/> },
      { name: 'Tailwind CSS', icon: <SiTailwindcss/> }
    ]
  },
  {
    name: "Backend",
    icon: <SiDotnet />,
    color: "#68A063",
    skills: [
      { name: 'PHP', icon: <FaPhp /> },
      { name: 'C#', icon: <SiCsharp /> },
      { name: '.NET', icon: <SiDotnet /> },
      { name: 'Python', icon: <FaPython /> },
      { name: 'Node.js', icon: <FaNodeJs /> },
      { name: 'Laravel', icon: <FaLaravel /> }
    ]
  },
  {
    name: "Database",
    icon: <FaDatabase />,
    color: "#336791",
    skills: [
      { name: 'MySQL', icon: <SiMysql /> },
      { name: 'NoSQL', icon: <SiMongodb/> },
    { name: 'Sql Server', icon: <SiMicrosoftsqlserver/> },
    { name: 'PostgreSQL', icon: <SiPostgresql/> }
    ]
  },
  {
    name: "DevOps & Misc",
    icon: <FaDocker />,
    color: "#2496ED",
    skills: [
      { name: 'Git', icon: <FaGitAlt /> },
      { name: 'Docker', icon: <FaDocker /> },
      { name: 'Redis', icon: <SiRedis/> },
      { name: 'Microsoft Azure', icon: <SiAzuredevops/> }
    ]
  }
];

const Skill: React.FC = () => {
  const [expandedStack, setExpandedStack] = useState<number | null>(null);

  const toggleStack = (index: number) => {
    setExpandedStack(expandedStack === index ? null : index);
  };

  return (
    <Container id="skills">
      <h2>Skills & Technologies</h2>
      <ScrollAnimation animateIn="fadeInUp">
        <StacksGrid>
          {skillStacks.map((stack, stackIndex) => (
            <div key={stackIndex}>
              <StackCard 
                isExpanded={expandedStack === stackIndex}
                onClick={() => toggleStack(stackIndex)}
                stackColor={stack.color}
              >
                <StackHeader>
                  <SkillIcon>{stack.icon}</SkillIcon>
                  <StackTitle>{stack.name}</StackTitle>
                  {expandedStack === stackIndex ? <FaChevronUp /> : <FaChevronDown />}
                </StackHeader>
              </StackCard>
              
              {expandedStack === stackIndex && (
                <ExpandedStack>
                  {stack.skills.map((skill, skillIndex) => (
                    <SkillCard key={skillIndex}>
                      <SkillIcon>{skill.icon}</SkillIcon>
                      <SkillName>{skill.name}</SkillName>
                    </SkillCard>
                  ))}
                </ExpandedStack>
              )}
            </div>
          ))}
        </StacksGrid>
      </ScrollAnimation>
    </Container>
  );
};

export default Skill;