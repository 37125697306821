import styled from "styled-components";

export const Container = styled.section`
  margin-top: 10rem;
  
  @media (max-width: 768px) {
    margin-top: 7rem;
  }
  
  h2 {
    text-align: center;
    font-size: 3.6rem;
    margin-bottom: 3rem;
    color: var(--section-title-color);
    
    @media (max-width: 768px) {
      font-size: 2.8rem;
      margin-bottom: 2rem;
    }
  }
  
  .projects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 2rem;
    padding: 1rem;
    overflow: visible;

    .project {
      padding: 2rem 1.8rem;
      background-color: #2b2b2b;
      border-radius: 1.2rem;
      transition: 0.25s;
      display: flex;
      flex-direction: column;
      height: 100%;
      color: #FFF;
      overflow: hidden; /* Prevent content from spilling out */
      
      @media (max-width: 768px) {
        padding: 1.6rem 1.4rem;
      }
      
      &:hover {
        transform: translateY(-5px);
        background-color: var(--pink);
      }

      header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--blue);
        margin-bottom: 2.4rem;
        
        @media (max-width: 768px) {
          margin-bottom: 1.8rem;
          flex-direction: column;
          align-items: flex-start;
        }
        
        .project-links {
          display: flex;
          align-items: center;
          gap: 1rem;
          
          @media (max-width: 768px) {
            margin-top: 1rem;
            width: 100%;
            justify-content: flex-start;
          }
          
          a {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        
        a > img {
          width: 3.2rem;
          height: auto;
          
          @media (max-width: 768px) {
            width: 2.8rem;
          }
        }
      }
      
      h3 {
        margin-bottom: 1.6rem;
        font-size: 2rem;
        word-break: break-word; /* Prevent long titles from overflowing */
        
        @media (max-width: 768px) {
          font-size: 1.8rem;
          margin-bottom: 1.2rem;
        }
      }

      p {
        letter-spacing: 0.06rem;
        margin-bottom: 2rem;
        line-height: 1.5;
        overflow-wrap: break-word; /* Handle long words */
        word-wrap: break-word;
        hyphens: auto;
        
        @media (max-width: 768px) {
          letter-spacing: 0.04rem;
          margin-bottom: 1.6rem;
          font-size: 1.4rem;
        }
        
        a {
          color: #FFFF;
          border-bottom: 1px solid var(--green);
          transition: color 0.25s;
          &:hover {
            color: var(--green);
          }
        }
      }

      footer {
        margin-top: auto;
        padding-top: 1.2rem;
        
        .tech-list {
          display: flex;
          align-items: center;
          flex-wrap: wrap; /* Allow tech list to wrap on smaller screens */
          gap: 1rem 1.5rem; /* Row gap, column gap */
          font-size: 1.3rem;
          opacity: 0.8;
          
          @media (max-width: 768px) {
            font-size: 1.2rem;
            gap: 0.8rem 1.2rem;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .projects {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 740px) {
    .projects {
      grid-template-columns: 1fr;
      gap: 1.5rem;
      padding: 0.5rem;
    }
  }
`