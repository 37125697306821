import styled from 'styled-components'

export const Container = styled.footer`
  background-color: var(--background-color);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 4rem 2rem 2rem;
  width: 100%;
`

export const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 3rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

export const FooterSection = styled.div`
  flex: 1;
  min-width: 250px;
  
  .logo {
    display: block;
    font-size: 2.4rem;
    color: var(--green);
    font-weight: 600;
    margin-bottom: 1rem;
    text-decoration: none;
    
    span:last-child {
      color: var(--section-title-color);
    }
  }
  
  .profession {
    color: var(--text-color);
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }
  
  h3 {
    font-size: 2rem;
    color: var(--section-title-color);
    margin-bottom: 2rem;
    font-weight: 500;
  }
  
  .built-with {
    margin-top: 1rem;
    
    p {
      display: flex;
      align-items: center;
      color: var(--text-color);
      font-size: 1.4rem;
      
      img {
        width: 2.4rem;
        height: 2.4rem;
        margin: 0 0.8rem;
      }
    }
  }
  
  @media (max-width: 768px) {
    min-width: 100%;
    margin-bottom: 2rem;
    
    .logo, .profession, h3 {
      text-align: center;
    }
    
    .built-with {
      justify-content: center;
      
      p {
        justify-content: center;
      }
    }
  }
`

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  
  .contact-item {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    
    img {
      width: 2rem;
      height: 2rem;
      filter: brightness(0) saturate(100%) invert(72%) sepia(93%) saturate(385%) hue-rotate(101deg) brightness(97%) contrast(87%);
    }
    
    a {
      color: var(--text-color);
      text-decoration: none;
      font-size: 1.5rem;
      transition: color 0.2s ease;
      
      &:hover {
        color: var(--green);
      }
    }
  }
  
  .social-links {
    display: flex;
    gap: 1.5rem;
    margin-top: 1rem;
    
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background-color: var(--green);
      color: var(--background-color);
      transition: all 0.3s ease;
      
      svg {
        font-size: 1.8rem;
      }
      
      &:hover {
        background-color: var(--pink);
        transform: translateY(-3px);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      }
    }
  }
  
  @media (max-width: 768px) {
    align-items: center;
    
    .contact-item {
      justify-content: center;
    }
  }
`

export const CopyrightSection = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 4rem;
  padding-top: 2rem;
  text-align: center;
  
  p {
    font-size: 1.4rem;
    color: var(--text-color);
    opacity: 0.7;
  }
    `