import styled from "styled-components";

export const Container = styled.div`
  margin-top: 4rem;
  display: grid;
  place-items: center;
  
  h2 {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 4.5rem;
    color: var(--section-title-color);
  }
  
  form {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 2rem;
    width: 100%;
    max-width: 70rem;
    
    input, textarea {
      width: 100%;
      padding: 1.6rem 2.5rem;
      border-radius: 1rem;
      outline: none;
      border: 1px solid rgba(255, 255, 255, 0.3);
      background: rgba(255, 255, 255, 0.05);
      color: white;
      font-size: 1.8rem;
      font-weight: 500;
      transition: all 0.2s ease-in-out;
      
      &::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
      
      &:focus {
        border-color: var(--green);
        box-shadow: 0 0 0 2px rgba(90, 99, 156, 0.2);
      }
    }

    textarea {
      height: 24rem;
      overflow-y: auto;
      resize: none;
    }

    button {
      padding: 1.6rem 8rem;
      text-transform: uppercase;
      font-size: 1.8rem;
      font-weight: 600;
      letter-spacing: 1px;
      border-radius: 1rem;
      background-color: var(--green);
      transition: all 0.2s ease-in-out;
      margin-top: 1.5rem;
      
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 5px 15px rgba(90, 99, 156, 0.3);
      }
      
      &:active {
        transform: translateY(-1px);
      }
    }
  }

  @media (max-width: 740px) {
    form {
      width: 90%;
      
      input, textarea {
        width: 100%;
      }
      
      button {
        width: 100%;
        padding: 1.8rem;
      }
    }
  }
`;

export const ContainerSucces = styled.div`
  margin-top: 10rem;
  text-align: center;
  padding: 3rem;
  background-color: rgba(90, 99, 156, 0.1);
  border-radius: 1rem;
  
  h3 {
    font-size: 2.8rem;
    margin-bottom: 1rem;
    color: var(--section-title-color);
  }
  
  p {
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  button {
    border-radius: 1rem;
    padding: 1.6rem 4rem;
    margin-top: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.8rem;
    color: #fbfbfb;
    background-color: var(--green);
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 5px 15px rgba(90, 99, 156, 0.3);
    }
  }
`;