import React from "react";
import { Timeline, Event } from "react-timeline-scribble";
import { Container, ImageWrapper, TimelineWrapper, CustomEventStyle, SectionTitle } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";
import { experienceData } from "../../data/ExperienceData";

export function Experience() {
  return (
    <Container id="experience">
      {/* This is the centered section title */}
      <SectionTitle>
        <ScrollAnimation animateIn="fadeIn">
          <h2>Experience</h2>
        </ScrollAnimation>
      </SectionTitle>
      
      
      <TimelineWrapper>
        <CustomEventStyle>
          <Timeline>
            {experienceData.map((experience, index) => (
              <ScrollAnimation animateIn="fadeInRight" key={index} delay={index * 100}>
                <Event 
                  interval={experience.interval} 
                  title={experience.company} 
                  subtitle={experience.position}
                >
                  {experience.descriptions.map((desc, descIndex) => (
                    <p key={descIndex} className="custom-event-text">
                      {desc}
                    </p>
                  ))}
                </Event>
              </ScrollAnimation>
            ))}
          </Timeline>
        </CustomEventStyle>
      </TimelineWrapper>
    </Container>
  );
}


