import { Container } from "./styles";
import RobinPic from "../../assets/robin.jpg";
import ScrollAnimation from "react-animate-on-scroll";

export function About() {
  return (
    <Container id="about">
      <div className="about-image">
        <ScrollAnimation animateIn="fadeIn" delay={0.3 * 1000}>
          <img src={RobinPic} alt="Robin" />
        </ScrollAnimation>
      </div>
      <div className="about-text">
        <h2>About Myself</h2>
        <ScrollAnimation animateIn="fadeInRight" delay={0.1 * 1000}>
          <p>
            Hi, I'm Reajul Islam Robin, a passionate software engineer specializing in web development with over a year of hands-on experience in various tech stacks including <b>Laravel, .NET, MERN</b> etc. I hold a Bachelor's degree in Computer Science and Engineering and currently work at ConnexPay, a US-based virtual payment company. My role involves managing and integrating complex payment systems with major platforms like Visa, Monavate, BlueSnap, and TSYS.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInRight" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>
            I am deeply fascinated by the technical challenges of building scalable and high-performance web applications. My expertise includes designing and developing robust APIs, optimizing application performance, and collaborating with cross-functional teams to deliver service-based solutions that meet business needs.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInRight" delay={0.3 * 1000}>
          <p>
            Feel free to explore my portfolio, where I showcase projects that reflect my commitment to driving impactful solutions through technology and collaboration.
          </p>
        </ScrollAnimation>
      </div>
    </Container>
  );
}
