export interface ProjectInterface {
  title: string;
  description: string;
  githubUrl?: string;
  deployedUrl?: string;
  techStack: string[];
}

export const projectsData: ProjectInterface[] = [
  {
    title: "Multi-vendor E-commerce Platform",
    description: "Developed a multi-vendor e-commerce platform using Laravel MVC, Javascript, Mysql, and SslCommerz, enabling vendors to manage products, orders, and inventory, and customers to browse products, add to cart, and checkout securely.",
    githubUrl: "#",
    deployedUrl: "#",
    techStack: ["Laravel", "JavaScript", "MySQL", "SslCommerz"]
  },
  {
    title: "Social Media Platform with Real-Time Messaging",
    description: "Built a social media application with real-time messaging using WebSockets, Laravel, and JavaScript. Implemented secure user authentication, profile management, and interactive features like posts, comments, and notifications.",
    githubUrl: "#",
    deployedUrl: "#",
    techStack: ["Laravel", "jQuery", "MySQL", "WebSockets"]
  },
  {
    title: "E-Commerce Hub with Auction-Centric Dynamics",
    description: "Developed a high-traffic auction platform in Laravel with real-time WebSocket updates and scalable architecture. Optimized database queries, reducing query response time by 40%, handling 1,000+ concurrent bids.",
    githubUrl: "#",
    deployedUrl: "#",
    techStack: ["Laravel", "jQuery", "MySQL", "WebSockets", "Redis"]
  },
  {
    title: "Online Movie Ticket Booking System",
    description: "Developed a scalable movie ticket booking application using ASP.NET MVC. Enabled users to browse movies, select showtimes, and book tickets with dynamic seat selection and secure payment integration.",
    githubUrl: "#",
    deployedUrl: "#",
    techStack: ["C#", ".NET", "Entity Framework", "SQL Server", "Bootstrap"]
  },
  {
    title: "AI-based Website Builder",
    description: "Developed an innovative website builder that leverages OpenAI's GPT models to generate custom website designs and content based on user prompts. Integrated with Laravel backend for user management, project storage, and seamless deployment, reducing website creation time by 70% for small businesses.",
    githubUrl: "#",
    deployedUrl: "#",
    techStack: ["Laravel", "React", "OpenAI API", "TailwindCSS", "MySQL"]
  },
  {
    title: "Event Management System",
    description: "Created a comprehensive event management platform using Laravel that handles event creation, ticketing, attendee management, and analytics. Implemented QR code check-in functionality, automated email notifications, and an interactive seating chart system that increased event organizer efficiency by 45%.",
    githubUrl: "#",
    deployedUrl: "#",
    techStack: ["Laravel", "MySQL", "Chart.js", "Stripe API"]
  }
];