import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 0;
  background-color: var(--background-color);

  .about-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;

    img {
      width: 100%;
      max-width: 400px;
      border-radius: 50%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      object-fit: cover;
    }
  }

  .about-text {
    flex: 1;
    padding-left: 2rem;

    h2 {
      font-size: 4rem;
      margin-bottom: 2rem;
      color: var(--section-title-color);
    }

    p {
      font-size: 1.6rem;
      line-height: 1.5;
      color: var(--text-color);
    }
  }

  @media (max-width: 960px) {
    flex-direction: column;

    .about-image {
      margin-right: 0;
      margin-bottom: 2rem;
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        max-width: 300px;
      }
    }

    .about-text {
      padding-left: 0;
      text-align: center;

      h2 {
        font-size: 3rem;
      }
    }
  }
`;