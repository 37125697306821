import styled, { keyframes } from 'styled-components';

interface StackCardProps {
  isExpanded: boolean;
  stackColor: string;
}

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const expandIn = keyframes`
  from {
    opacity: 0;
    max-height: 0;
    transform: scaleY(0.8);
    transform-origin: top;
  }
  to {
    opacity: 1;
    max-height: 500px;
    transform: scaleY(1);
    transform-origin: top;
  }
`;

export const Container = styled.section`
  padding: 6rem 2rem;
  
  @media (max-width: 768px) {
    padding: 4rem 1.5rem;
  }

  h2 {
    text-align: center;
    font-size: 3.2rem;
    margin-bottom: 4rem;
    color: var(--section-title-color);
    
    @media (max-width: 768px) {
      font-size: 2.8rem;
      margin-bottom: 3rem;
    }
  }
`;

export const StacksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

export const StackHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  width: 100%;
  
  svg:last-child {
    margin-left: auto;
    font-size: 1.6rem;
    opacity: 0.7;
    transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    will-change: transform;
  }
`;

export const StackTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
  color: white;
  margin: 0;
  transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  
  @media (max-width: 480px) {
    font-size: 1.6rem;
  }
`;

export const StackCard = styled.div<StackCardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: rgba(37, 42, 54, 0.8);
  border-radius: 8px;
  border: 1px solid ${props => props.isExpanded ? props.stackColor : 'rgba(255, 255, 255, 0.05)'};
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), 
              transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1), 
              box-shadow 0.4s ease;
  cursor: pointer;
  box-shadow: ${props => props.isExpanded ? `0 8px 20px rgba(0, 0, 0, 0.2), 0 0 15px rgba(${props.stackColor}, 0.15)` : '0 2px 10px rgba(0, 0, 0, 0.1)'};
  will-change: transform, box-shadow, border-color;
  
  ${StackHeader} {
    color: ${props => props.isExpanded ? props.stackColor : 'var(--green)'};
    
    svg:last-child {
      transform: ${props => props.isExpanded ? 'rotate(-180deg)' : 'rotate(0)'};
    }
  }
  
  &:hover {
    transform: translateY(-3px);
    border-color: ${props => props.stackColor};
    background-color: rgba(40, 45, 57, 0.9);
    
    ${StackTitle} {
      color: ${props => props.stackColor};
    }
  }
  
  &:active {
    transform: translateY(-1px);
    transition: transform 0.1s;
  }
  
  @media (max-width: 480px) {
    padding: 1.5rem;
  }
`;

export const ExpandedStack = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 1.2rem;
  margin-top: 1.5rem;
  animation: ${expandIn} 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  overflow: hidden;
  transform-origin: top;
  will-change: opacity, transform, max-height;
  
  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
`;

export const SkillIcon = styled.div`
  font-size: 2.8rem;
  color: inherit;
  margin-bottom: 0.8rem;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  will-change: transform, color;
  
  @media (max-width: 480px) {
    font-size: 2.4rem;
    margin-bottom: 0.6rem;
  }
`;

export const SkillName = styled.span`
  font-size: 1.4rem;
  color: #ffffff;
  text-align: center;
  transition: color 0.3s ease;
  font-weight: 500;
  
  @media (max-width: 480px) {
    font-size: 1.3rem;
  }
`;

export const SkillCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  background-color: rgba(37, 42, 54, 0.5);
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
              transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
  aspect-ratio: 1 / 1;
  animation: ${fadeInUp} 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-fill-mode: both;
  will-change: transform, background-color, border-color;
  
  &:nth-child(1) { animation-delay: 0.05s; }
  &:nth-child(2) { animation-delay: 0.1s; }
  &:nth-child(3) { animation-delay: 0.15s; }
  &:nth-child(4) { animation-delay: 0.2s; }
  &:nth-child(5) { animation-delay: 0.25s; }
  &:nth-child(6) { animation-delay: 0.3s; }
  
  @media (max-width: 480px) {
    padding: 1rem;
  }

  &:hover {
    transform: translateY(-3px) scale(1.03);
    background-color: rgba(90, 99, 156, 0.15);
    border-color: var(--green);
    
    ${SkillIcon} {
      color: white;
      transform: scale(1.15);
    }
    
    ${SkillName} {
      color: var(--green);
    }
  }
`;