export interface ExperienceInterface {
  interval: string;
  company: string;
  position: string;
  descriptions: string[];
}

export const experienceData: ExperienceInterface[] = [
  {
    interval: "2023 – Present",
    company: "ConnexPay",
    position: "Software Developer",
    descriptions: [
      "Developed and optimized the internal on-boarding process for new users using C#, ASP.NET MVC, and SQL Server, adhering to SOLID principles for maintainability and scalability. Demonstrated a strong learning mindset and high work ethics by managing tight deadlines and working under pressure, all while maintaining meticulous attention to detail and effective communication.",
      "Integrated and maintained international virtual payment systems using ASP.NET Core, Entity Framework, and Web API to ensure secure and seamless transactions."
    ]
  },
  {
    interval: "2023 – Present",
    company: "Teton Private Ltd",
    position: "Engineer I, Software",
    descriptions: [
      "Developed and maintained code for various in-house projects using NodeJS, Laravel, Bootstrap, and Tailwind, ensuring comprehensive documentation and technical specifications. Demonstrated effective problem-solving abilities, clear communication, and strong attention to detail to meet project requirements and deadlines."
    ]
  },
  // You can add more experience entries here
  {
    interval: "2022 – 2023",
    company: "Freelance",
    position: "Full Stack Developer",
    descriptions: [
      "Designed and developed responsive web applications for various clients using Laravel, React , MySQL.",
      "Implemented authentication systems and RESTful APIs to ensure secure and efficient data exchange."
    ]
  }
]