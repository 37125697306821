import { Container, FooterSection, FooterContent, ContactInfo, CopyrightSection } from './styles'
import reactIcon from '../../assets/react-icon.svg'
import emailIcon from '../../assets/email-icon.svg'
import phoneIcon from '../../assets/phone-icon.svg'
import { FaLinkedinIn, FaGithubAlt, FaWhatsapp, FaFacebook } from 'react-icons/fa'

export function Footer() {
  const currentYear = new Date().getFullYear()
  
  return (
    <Container className="footer">
      <FooterContent>
        <FooterSection>
          <a href="https://robinislam.netlify.app" className="logo">
            <span>Reajul Islam</span>
            <span> Robin</span>
          </a>
          <p className="profession">Software Engineer</p>
        </FooterSection>
        
        <FooterSection>


        </FooterSection>
        
        <FooterSection>
        <h3>Contact</h3>
        <ContactInfo>
            <div className="contact-item">
              <img src={emailIcon} alt="Email" />
              <a href="mailto:reajulbd786@gmail.com">reajulbd786@gmail.com</a>
            </div>
            <div className="contact-item">
              <img src={phoneIcon} alt="Email" />
            +8801679136555
            </div>
            <div className="social-links">
              <a href="https://www.linkedin.com/in/robin-mxr" target="_blank" rel="noopener noreferrer">
                <FaLinkedinIn />
              </a>
              <a href="https://github.com/robinmxr" target="_blank" rel="noopener noreferrer">
                <FaGithubAlt />
              </a>
              <a href="https://www.linkedin.com/in/robin-mxr" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp />
              </a>
              <a href="https://www.linkedin.com/in/robin-mxr" target="_blank" rel="noopener noreferrer">
                <FaFacebook />
              </a>
            </div>
          </ContactInfo>
        </FooterSection>
      </FooterContent>
      
      <CopyrightSection>
        <p>&copy; {currentYear} Reajul Islam. All rights reserved.</p>
      </CopyrightSection>
    </Container>
  )
}
