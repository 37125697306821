import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7rem;
  position: fixed;
  z-index: 999;
  padding: 0 2.5rem;
  transition: all 0.3s ease;
  
  /* Initially completely transparent to blend with hero */
  background-color: transparent;
  backdrop-filter: none;
  box-shadow: none;
  
  /* Only add styling when scrolled */
  &.header-fixed {
    height: 6.5rem;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    background-color: rgba(18, 18, 20, 0.85);
    backdrop-filter: blur(12px);
  }

  .logo {
    font-size: 2.2rem;
    font-weight: 700;
    text-decoration: none;
    transition: all 0.3s ease;
    letter-spacing: -0.02em;
    
    span:first-child {
      color: var(--green);
      transition: all 0.3s ease;
    }
    
    span:last-child {
      color: white;
      transition: all 0.3s ease;
    }
    
    &:hover {
      transform: translateY(-1px);
      
      span:first-child {
        color: white;
      }
      
      span:last-child {
        color: var(--green);
      }
    }
  }

  nav {
    display: flex;
    align-items: center;
    gap: 2rem;
    
    a {
      position: relative;
      color: rgba(255, 255, 255, 0.9);
      padding: 0.6rem 0.8rem;
      font-weight: 500;
      font-size: 1.5rem;
      text-decoration: none;
      transition: all 0.3s ease;
      letter-spacing: -0.01em;
      
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background-color: var(--green);
        transition: width 0.3s ease;
      }
      
      &:hover {
        color: white;
        
        &::after {
          width: 100%;
        }
      }
      
      &.active {
        color: var(--green);
        font-weight: 600;
      }
      
      &.button {
        padding: 0.6rem 1.8rem;
        border-radius: 4px;
        border: 1px solid var(--green);
        background-color: transparent;
        color: var(--green);
        font-weight: 500;
        margin-left: 1rem;
        white-space: nowrap;
        transition: all 0.3s ease;
        
        &:hover {
          background-color: var(--green);
          color: #111;
          transform: translateY(-1px);
        }
        
        &::after {
          display: none;
        }
      }
    }
  }

  .menu {
    display: none;
    width: 2.4rem;
    height: 2.4rem;
    position: relative;
    cursor: pointer;
    
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 70%;
      height: 2px;
      left: 15%;
      transition: all 0.3s ease;
      background-color: var(--green);
      border-radius: 2px;
    }
    
    &::before {
      top: 40%;
    }
    
    &::after {
      bottom: 40%;
    }
    
    &.active {
      &::before {
        transform: rotate(45deg);
        top: 50%;
      }
      
      &::after {
        transform: rotate(-45deg);
        bottom: 45%;
      }
    }
  }

  @media (max-width: 960px) {
    padding: 0 1.5rem;
    height: 6rem;
    
    /* Important: Mobile header is also transparent initially */
    background-color: transparent;
    backdrop-filter: none;
    
    /* Only on mobile, when fixed class is added */
    &.header-fixed {
      background-color: rgba(18, 18, 20, 0.85);
      backdrop-filter: blur(8px);
    }
    
    .menu {
      display: block;
      z-index: 1001;
    }
    
    nav {
      position: fixed;
      top: 0;
      right: -100%;
      width: 75%;
      height: 100vh;
      padding: 6rem 2rem;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 2.5rem;
      background-color: rgba(16, 16, 20, 0.98);
      backdrop-filter: blur(12px);
      transition: all 0.4s ease;
      
      a {
        font-size: 1.8rem;
        opacity: 0;
        width: 100%;
        text-align: center;
        padding: 1rem;
        
        &::after {
          bottom: -2px;
          width: 0;
        }
        
        &:hover::after {
          width: 30%;
        }
        
        &.active::after {
          width: 0; /* No underline for active state in mobile */
        }
        
        &.button {
          margin: 1.5rem 0 0;
          padding: 1rem;
          width: 80%;
        }
      }
      
      &.active {
        right: 0;
        
        a {
          opacity: 1;
          
          &:nth-child(1) {
            transition-delay: 0.1s;
          }
          
          &:nth-child(2) {
            transition-delay: 0.15s;
          }
          
          &:nth-child(3) {
            transition-delay: 0.2s;
          }
          
          &:nth-child(4) {
            transition-delay: 0.25s;
          }
          
          &:nth-child(5) {
            transition-delay: 0.3s;
          }
        }
      }
    }
  }
`;