import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
import phoneIcon from "../../assets/phone-icon.svg";
import locationIcon from "../../assets/location.svg";
import { Form } from "../Form/Form";
import ScrollAnimation from "react-animate-on-scroll";
import { FaLinkedinIn, FaGithubAlt, FaWhatsapp, FaFacebookF } from "react-icons/fa";
import Illustration from "../../assets/illustration.svg"; // Make sure this path is correct

export function Contact() {
  return (
    <Container id="contact">
      <header>
        <h2>Contact</h2>
      </header>
      <Form />
      <div className="contacts">
        <div>
          <a href="mailto:reajulbd786@gmail.com">
            <img src={emailIcon} alt="Email" />
          </a>
          <a href="mailto:reajulbd786@gmail.com">reajulbd786@gmail.com</a>
        </div>
        <div>
          <a href="tel:+8801679136555">
            <img src={phoneIcon} alt="Phone No" />
          </a>
          <a href="tel:+8801679136555">(+88) 01679136555</a>
        </div>
        <div>
          <img src={locationIcon} alt="Address" />
          <a> Dhaka, Bangladesh</a>
        </div>
      </div>

   
      
    </Container>
  );
}